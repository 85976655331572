.review-container {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .review-slider {
    position: relative;
    width: 80%;
    margin: 0 auto;
  }
  
  .review-card {
    display: flex;
    justify-content: center;
  }
  
  .review-card img {
    max-width: 30%;
    max-height: 60%;
  }
  
  .review-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    font-size: 24px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .review-icon:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .review-icon:first-child {
    left: 0;
  }
  
  .review-icon:last-child {
    right: 0;
  }
  