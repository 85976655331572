/* hero.css */
.abt-container{
  width: 100%;
}

.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.hero-content {
  text-align: center;
}

.hero-text {
  font-family:  Poppins,  Arial, sans-serif;
  color: rgb(62, 8, 8);
  margin: 10px 0;
  font-weight: 500;;
}

#s1 {
  font-size: 22px;
  font-weight: bold;
}
#s2{
  font-size: 40px;
  margin-top: 3%;
}

#s3{
  font-size: 40px;
  margin-top: 1.5%;
}

#s4 {
  font-size: 22px;
  
}

#s5{
  font-size: 22px;
  margin-top: 2%;
}



/* button */

#btn {
appearance: button;
background-color: rgb(89, 228, 244);;
border: 1px solid #1652F0;
border-radius: 20px;
box-sizing: border-box;
color: black;
cursor: pointer;
font-family: Graphik,-apple-system,system-ui,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
font-size: 14px;
line-height: 1.15;
overflow: visible;
padding: 12px 16px;
position: relative;
text-align: center;
text-transform: none;
transition: all 80ms ease-in-out;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
width: fit-content;
}

#btn:disabled {
opacity: .5;
}

#btn:focus {
outline: 0;
}

#btn:hover {
background-color: white;
border-color: #0A46E4;
}

#btn:active {
background-color: #0039D7;
border-color: #0039D7;
}

.hero-image {
   margin-top: 80px;
  margin-right: 5%; 
}

.hero-img {
  
  width: 550px; 
  height: 400px; 
}

.star {
  display: flex;
  justify-content: center;
  margin-top: 2%;
}

#txt {
  padding-left: 0%;
  padding-right: 5%;
  padding-top: 5%;
  margin-left: 6%;
}

#para1{
  line-height: 2; 
  color: black;
  font-size: 20px;
}

.band{
  
      height: 400px;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      background: #060322;
     margin-top:  10% ;
      box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
      justify-content: center;
      align-items: center;
      text-align: center;
}

.band-text{
  color: aquamarine;
}
#bp {
  color: aquamarine;
  justify-content: center;
  padding-top: 80px;
  align-items: center;
}

#bp2 {
  font-size: 35px;
  font-family:Arial, Helvetica, sans-serif Poppins ;
}

#dt {
  justify-content: center;
  text-align: center;
  padding-top: 5%;
  font-size: 40px;
}
.doc-image {
  margin-top: 0px;
 margin-right: 5%; 
}

.dr-img {
 
 width: 550px; 
 height: auto; 
}

.Doctor-info{
  display: flex;
  justify-content: center;
}

.d-info{
  padding-left: 7%;
  margin-top: 35px;
  
}

.d-info {
  width: 70%;
  max-height: 700px;
  padding: 10px; 
  margin-top: 20px; 
  font-size: 20px
}
#dpara {
  line-height: 1.5;
  color: black;
  font-size: 20px;
}


#ot {
  justify-content: center; 
  text-align: center;
  margin-top: 50px;
  font-size: 30px;
}

.doctor-img{
  width: 250px; 
  height: auto; 
}

.dcards{
  display: flex;
  justify-content: center;
  gap: 250px;
  margin-top: 100px;
}

.container{
  width: 80%;
  height: auto;
  padding: 0 8%;
  margin-bottom: 5%;
}

.container h1{
  text-align: center;
  padding-top: 10%;
  margin-bottom: 30px;
  font-weight: 500;
  position: relative;
  font-size: 20px;
}
.container h3{
  text-align: center;
  padding-top: 20px;
  font-size: 30px;
  font-weight: 500;
  position: relative;
  font: 'Poppins';
}

.container h1 ::after {
  content: '';
  background: #303ef7;
  width: 100px;
  height: 5px;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.services {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 25px 10px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background: transparent;
  transition: transform 0.5s, background 0.5s;
}

.services i {
  font-size: 40px;
  margin-bottom: 10px;
  color : black;
}

.services h2 {
  font-weight: 600;
  margin-bottom: 8px;
}

.services:hover {
  background: #28a7e2;
  color: #FFFFFF;
  transform: scale(1.05);
  color: #060322;

}

.services i:hover  {
  color: white;
}

@media only screen and (max-width: 768px) {
  .abt-container{
    width: 100%;
  }

  .hero-container {
    display: flex;
  }
  
  .hero-text {
    font-size: 16px; 
  }
  
  .hero-img {
    width: 100%;
    height: auto; 
    object-fit: cover; 
  }
  
  .doctor-img {
    width: 40%; 
    height: auto; 
    object-fit: cover;
    margin-top: 10px;
  }
  
  #s2 {
    font-size: 14px; 
  }
  
  #bp2 {
    font-size: 14px; 
    margin-top: 10px; 
  }
  
  #dpara {
    font-size: 15px;
    width: 100%; 
    margin: 20px 20px 20px 0; 
    float: left; 
  }
  
  .band-text {
    font-size: 14px; 
  }

  .services{
    width: 60%;
  }
  
  .services h2,
  .services p {
    font-size: 6px;
  }
  
  .services i {
    font-size: 24px;
  }

  .container{
    width: 80%;
    margin-bottom: 10%;
  }

  .container h3{
    font-size: small;
  }

  .doc-image {
    width: 80%; 
    height: auto;
    float: right; 
    margin: 20px 0 20px 20px; 
  }

  .dcards{
    display: flex;
    flex-direction: row;
  }

  .dr-img {
    width: 100%;
    height: auto; 
    object-fit: cover;
  }

  .star {
    display: flex;
    justify-content: center;
    margin-top: 2%;
  }
  
  #txt {
    padding-left: 0%;
    padding-right: 5%;
    padding-top: 5%;
    margin-left: 6%;
  }
  
  #para1{
    line-height: 2; 
    color: black;
  }

}