.testimonials-container {
  font-size: small;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.testimonials-slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 700px;
  margin: 0 auto;
}

.testimonial-card {
  text-align: center;
  padding: 30px;
  border: 2px solid #ccc;
  border-radius: 10px;
  background-color: antiquewhite;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.testimonial-card:hover{
  cursor: pointer;
  transform: scale(1.1);
  box-shadow: #333;
}

.quote {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #333;
}

.author {
  text-align: end;
  font-size: 18px;
  font-style: italic;
  font-weight: 700;
  color: #777;
}

.author i{
  font-size: 20px;
  margin-right: 10px;
  color: #333;
}

.author:hover{
  color: #1BCDE3;
}


.slider-icon {
  width: 10%;
  height: auto;
  border: 2px solid #ccc;
  border-radius: 40px;
  cursor: pointer;
  font-size: 32px;
  color: #555;
  padding: 10px;
  transition: background-color 0.8s, color 0.3s;
  margin-right: 10px;
  margin-left: 10px;
}

.slider-icon:hover {
  background: #333;
  color: aqua;
}

/* .dr-img {
  
  width: 350px; 
  height: 200px; 
} */