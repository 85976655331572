.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: sticky;
  top: 0; 
  z-index: 1000;
  background-color: transparent;
  backdrop-filter: blur(50px); /* Adjust the blur amount as needed */
}


.logo-container {
  width: 40%; 
  height: auto;
}

.logo{
  margin-right: 100%;
  width: 50%;
  height: auto;
}

.menu-links {
  display: flex;
  align-items: center;
}

.menu-links a {
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
  color: #333;
  margin-right: 2rem;
  margin-left: 2rem;
}

.menu-links a:hover{
  transform: scale(1.1);
  color: #1BCDE3;
}

.mobile-menu {
  display: none;
}

.book-appointment{
  font-weight: 500;
  padding: 12px 12px;
  background-color: black;
  color: aliceblue;
  border-radius: 20px;
  border: 1px solid  #ffffff;
  transition: 0.2s;
}


.book-appointment:hover{
  background-color: #172F52;
  color: azure;
  transform: scale(1.1);
}

.menu-btn {
  display: none;
}

.dropdown-wrapper {
  width: 180px;
  position: relative;
  display: inline-block;
}

.dropdown-wrapper i{
  margin-left: 5px;
}

.dropdown {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 100%;
  left: 0;
  background-color: #333;
  border: 1px solid #ccc;
  border-top: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown-column {
  width: 50%;
}

.dropdown-content {
  padding: 10px;
}

.dropdown-content a {
  font-size: medium;
  display: block;
  color: #ffffff;
  text-decoration: none;
  padding: 8px 10px;
}

.dropdown-wrapper:hover .dropdown-content {
  display: block;
}

.services-link {
  font-size: 20px;
  font-weight: 700;
  color: #333;
  cursor: pointer;
}

.services-link:hover{
  transform: scale(1.1);
  color: #1BCDE3;
}
/* Media Query for responsive design */
@media screen and (max-width: 768px) {
  .menu-links {
    display: none;
  }
  
  .logo{
      width: 80%;
  }

  .mobile-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 5rem; /* Adjust as needed */
    right: 0;
    width: 70%;
    background-color: #333;
    padding: 2rem 2rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 1000;
  }

  .mobile-menu a {
    text-decoration: none;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
    margin-top: 1rem;
    margin-bottom: 2rem;
    transition: color 0.3s ease; 
  }

  .mobile-menu a:hover {
    transform: scale(1.1);
    color: #1BCDE3;
  }

  .menu-btn {
    display: block;
    cursor: pointer;
    margin-left: 20px;
  }

  .menu-btn i {
    font-size: 1.5rem;
    color: #333;
  }

  .dropdown-wrapper{
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    position: relative;
    display: inline-block;
    
  }
  
  .dropdown {
    display: flex;
    flex-direction: row; 
    width: 100%; 
    position: absolute; 
    top: auto; 
    left: auto;
    border: none; 
    box-shadow: none; 
    border-top: 1px solid #ccc; 
    background-color: #333; 
  }

  .dropdown-column {
    width: 100%; 
    padding: 10px; 
    display: flex; 
    flex-direction: row; 
  }

  .dropdown-content {
    display: block;
    padding: 0; 
  }

  .dropdown-content a {
    font-size: 12px; 
    display: flexbox; 
    width: 20%; 
    box-sizing: border-box; 
    text-align: center; 
    margin-bottom: 5px; 
  }

  .services-link {
    margin-top: 3rem;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 3rem;
    color: #ffffff;
    cursor: pointer;
  }

  .services-link:hover {
    transform: none; 
    color: #1BCDE3; 
  }
}

