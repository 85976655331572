.footer{
    height: fit-content;
    padding: 4rem 6rem;
    background: linear-gradient(to left, #000000, #01959a);
    color: aliceblue;
}

.footer-title{
    font-size: 1.2rem;
    font-weight: 800;
}

.footer a{
    color: #1BCDE3;
}

.footer i{
    margin-right: 5px;
}

.top{
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-top: 10px;
}

.top i{
    color: azure;
    font-size: 1.8rem;
    margin-left: 1rem;
}

.top i:hover{
    color: #1BCDE3;
}

.bottom{
    padding-top: 1rem;
    text-align: start;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.bottom div{
    display: flex;
    flex-direction: column;
}

.bottom h4{
    font-size: 1.4rem;
    padding: 1rem 0rem .8rem 0;
}

.bottom a{
    text-decoration: none;
    color: white;
    padding-bottom: .4rem;
    font-size: 1.2rem;
}

.bottom a:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.div .a:hover{
    background-color: #01959a;
}
 .contact-column h4{
    padding-bottom: 0;
 }

@media screen and (max-width :786px) {
    .footer{
        padding: 2rem 1rem;
    }
    .footer-title{
        font-size: medium;
    }
    .footer i{
        padding: 1rem 1rem;
    }
    .bottom div{
        width: 50%;
    }
    .contact-column p{
        font-size: x-small;
    }
}

