
.App {
  text-align: center;
  position: relative;
}

body {
  background-image: url('images/backgroundimage/background\ image\ for\ dental\ website.jpg');
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
}

.blur-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%; 
  filter: blur(5px); 
  z-index: -1;
}



@media only screen and (max-width: 768px) {
  .split-color-background {
    position: fixed;
    right: 0;
    width: 35%;
    background-color: slategray;
    z-index: -1;
  }
}
