body{
    text-align: center;
}
  
  .services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  
  
  .service-card {
    border-radius: 20px;
    box-shadow: 0 4px 8px #0000001a;
    overflow: hidden;
    max-width: 220px;
    height: auto;
    transition: all 0.3s ease;
  }

  .service-card:hover{
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: #333;
  }
  
  .service-card img {
    margin-top: 10px;
    width: 80%;
    height: auto;
    object-fit: cover;
    border-radius: 20px;
  }
  
  .service-info {
    padding: 10px;
  }
  
  .service-info h5 {
    margin-top: 5px;
    font-size: 20px;
    text-decoration: none;
    color: #333;
  }
  
  .explore {
    color: #007bff; 
    font-weight: 500;
  }
  