.gallery {
    font-family: "Times New Roman", Times, serif; /* Set font to Times New Roman */
    /* Your existing gallery styles */
  }
  
  
  .photos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
    
  }
  
  .photo-container {
    position: relative;
    margin: 20px;
    width: 150px; /* Set a fixed width for the container */
    height: 150px; /* Set a fixed height for the container */
  }
  
  .photo-container img {
    border-radius: 10%;
    width: 100%; /* Make the image fill the container width */
    height: 100%; /* Make the image fill the container height */
    object-fit: cover; /* Maintain aspect ratio and cover the container */
    cursor: pointer;
  }
  
  .view-button {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
  
    cursor: pointer;
    border-radius: 50%;
  }
  
  /* Add styles for the modal overlay */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Add styles for the modal */
  .modal {
    background-color: rgb(249, 243, 243);
    padding: 20px;
    border-radius: 8px;
  }
  
  /* Add styles for the close button */
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #555;
    cursor: pointer;
  }
  
  /* Add styles for the displayed image */
  .modal img {
    max-width: 100%; /* Adjust the maximum width of the image */
    max-height: 60vh; /* Adjust the maximum height of the image */
  }
  
  /* Media query for mobile devices */
  @media screen and (max-width: 768px) {
    .photos {
      justify-content: center; /* Center align the photos */
    }
  
    .photo-container {
      width: 120px; /* Reduce the width of the container for mobile */
      height: 170px; /* Reduce the height of the container for mobile */
      margin: 10px; /* Add some space between photos for mobile */
    }
  
    .view-button {
      bottom: -20px; /* Adjust the position of the view button for mobile */
    }
    .photo-container img {
      width: 100%; /* Make the image fill the container width */
      height: 80%; /* Make the image fill the container height */
      object-fit: cover; /* Maintain aspect ratio and cover the container */
      cursor: pointer;
    }
  }