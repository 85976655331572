.dentistry{
  width: 90%;
  text-align: left;
}
.posi_tion {
    text-align: left;
    width: 100%;
    left: 15%;
    top: 20%;
    margin-left: 30px;
    margin-right: 20px;
  }
  
  .img_deg {
    float: right;
    width: 40%;
    left: 10%;
    bottom: 2%;
  }
 li{
    font-weight: 300;
  }
  h3{
    font-weight: 400;
    font-size: small;
    line-height: 1.5;
    
  }
  h1{
    padding: 10px;
  }
  