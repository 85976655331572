/* App.css */

/* Common styles */
.slider-container {
  position: relative;
  margin: 2vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.slide {
  width: 100%; /* Adjusted width */
  height: 100%; /* Adjusted height */
  border-radius: 10px;
  background-size: contain; /* Changed from cover to contain */
  background-position: center;
}

.dots-container {
  display: flex;
  justify-content: center;
}

.dot {
  margin: 0 3px;
  cursor: pointer;
  font-size: 20px;
  color: #808080; /* Greyish color */
}

.info-container {
  width: 30%; /* Adjust width as needed */
  padding: 0 20px; /* Add padding for spacing */
  position: absolute;
  top: 42%;
  transform: translateY(-50%);
  right: 0;
  margin-right: 10px;
  font-weight: 200; /* Add margin to prevent overlapping */
  font-size: 20px;

}
p{
  font-size:auto;
}

/* Media queries */
@media only screen and (max-width: 768px) {
  
  .slide {
      width: 100%; /* Increase slide width for mobile view */
      height: 20%; /* Adjusted height */
      border-radius: 5px;
      background-position: center;
      background-size: contain; /* Changed from cover to contain */
      margin-left: 5%; /* Adjust left margin for centering */
  }

  .info-container {
      width: 80%; /* Adjust width for smaller screens */
      position: relative; /* Change position to relative to prevent overlapping */
      margin: 10px; /* Center horizontally */
      top: auto;
      transform: none;
      right: auto;
      font-size: 70%;
      font-weight: 270;
  }

  .dot {
      font-size: 16px; /* Adjust font size for smaller screens */
  }
}
