.card {
  display: flex;
  border-radius: 24px;
  overflow: hidden;
  background-color: antiquewhite;
  margin-left: 20px;
  margin-right: 20px;
}

.card-image {
  flex: 1;
  left: 50px;
}

.card-container{
  margin-top: 6vh;
}

.card-image img {
 margin-top: 10vh;
 margin-left:20px;
  width: 60%;
  height: auto;
  border-radius: 10%;
}

.card-image img:hover{
  cursor: pointer;
  transform: scale(1.1);
}

.card-content {
  flex: 2;
  padding: 10px;
}

.additional-content {
  flex: 2;
  height: 10vh;
  width: 90vh;
  margin-top:20vh;
  margin-right: 3%;
  margin-bottom: 20px;
  font-size:large ;
  font-weight: 500;
}

.additional-content a{
  color: black;
  font-size:large;
  font-weight: 500;
  
}

.additional-content a:hover{
  color: blue;
  font-size:large;
}

@media only screen and (max-width: 768px) {
  .card {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
  }

  .card-image,
  .card-content,
  .additional-content {
    flex: 1;
    width: 100%;
    padding: 0 10px;
    text-align: center;
    font-weight: 500;
  }

  .card-image img {
    margin: 20px auto;
    width: 60%;
    height: auto;
  }

  .additional-content {
    margin-top: 5px;
    width: 90%;
    font-size: medium;
    
  }
}
